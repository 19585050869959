import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { LegoSetModel } from 'model';
import { LegoSetHeading } from './heading';
import { LegoSetExternalLinks } from './external_links';
import { LegoSetBricklinkPrices } from './bricklink_prices';
import { LegoSetRebrickableImage } from './rebrickable_image';
import { LegoSetMyStoreInfo } from './my_store_info';
import { LegoSetRecentBuys, MinifigThumbnail } from 'components';
import { SessionContext, TSessionContext } from 'lib';

interface Props {
  legoSet: LegoSetModel | null;
}

export const LegoSetCard: React.FC<Props> = ({ legoSet }: Props) => {
  const { isHeartlandLive } = React.useContext(SessionContext) as TSessionContext;

  if (!legoSet) {
    return (<></>);
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5.5}>
          <LegoSetHeading detailView={false} legoSet={legoSet} />
          <LegoSetExternalLinks legoSet={legoSet} />
          <LegoSetRebrickableImage legoSet={legoSet} />
          <Box>
            <Grid container spacing={2}>
              {legoSet.minifigs.map((inventory) =>
                <Grid item xs={2}>
                  <MinifigThumbnail minifig={inventory.minifig} quantity={inventory.quantity} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6.5}>
          <h4>Price Guide</h4>
          <LegoSetBricklinkPrices legoSet={legoSet} />
          {isHeartlandLive() && <LegoSetMyStoreInfo legoSet={legoSet} />}
          <LegoSetRecentBuys legoSet={legoSet} maxHeight={800} />
        </Grid >
      </Grid>
    </Box >
  );
};
