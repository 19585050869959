import * as React from "react";

import { HeartlandModel, UserModel, UnitModel, models } from "model";
import { api } from "lib";
import { UserSetting } from "model/user_setting";
import { AlertColor } from "@mui/material";
import { StateSelector } from "components";


export interface FlashMessage {
  message: string;
  status: AlertColor;
}

export type FlashMessageState = FlashMessage | null;

export type TSessionContext = {
  currentUser: UserModel | null;
  currentHeartland: HeartlandModel | null;
  currentUnit: UnitModel | null;
  units: UnitModel[] | undefined;
  message: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  isAdmin: () => boolean;
  isOwner: () => boolean;
  isHeartlandLive: () => boolean;
  setCurrentHeartland: (heartlandId: number) => boolean;
  setCurrentUnit: (unitId: number) => boolean;
  getSetting: (name: string) => string | boolean | number | null;
  flashMessage: FlashMessageState;
  setFlashMessage: (message: FlashMessageState) => void;
}

type SessionState = {
  user: UserModel | null;
  message: string | null;
  heartland: HeartlandModel | null;
  unit: UnitModel | null;
}

interface SessionProps {
  children: React.ReactNode;
}

export const SessionContext = React.createContext<TSessionContext | null>(null);

const getHeartland = (user: UserModel) => user.heartlands ? user.heartlands[0] : null;
const getUnit = (user: UserModel) => user.units ? user.units[0] : null;

const loadSession = (): SessionState => {
  const res = localStorage.getItem('user');
  const expires = localStorage.getItem('expires');
  if (res && expires) {
    if (parseInt(expires) * 1000 > Date.now()) {
      const user = JSON.parse(res);
      return { user: user, message: null, heartland: getHeartland(user), unit: getUnit(user) };
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('id');
      localStorage.removeItem('expires');
      return { user: null, message: "Session expired, please log in again.", heartland: null, unit: null };
    }
  }
  return { user: null, message: "", heartland: null, unit: null };
}

export const SessionProvider: React.FC<SessionProps> = (props: SessionProps) => {
  const { children } = props;
  const [state, setState] = React.useState<SessionState>(loadSession);
  const [flashMessage, setFlashMessage] = React.useState<FlashMessageState>(null);

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const login = async (email: string, password: string): Promise<boolean> => {
    const resp = await api.auth(email, password);
    if (resp) {
      localStorage.setItem('user', resp.user);
      localStorage.setItem('id', resp.token);
      localStorage.setItem('refresh', resp.refresh);
      const decodedToken = parseJwt(resp.token);
      localStorage.setItem('expires', decodedToken.exp)
      const user = JSON.parse(resp.user);
      setState({ user: user, message: null, heartland: getHeartland(user), unit: getUnit(user) });
      return true;
    } else {
      setState({ user: null, message: "login invalid", heartland: null, unit: null });
      return false;
    }
  };

  const isAdmin = React.useCallback((): boolean => state.user && models.user.isAdmin(state.user) ? true : false, [state]);
  const isOwner = React.useCallback((): boolean => state.user && models.user.isOwner(state.user) ? true : false, [state]);
  const isHeartlandLive = React.useCallback((): boolean => state.heartland?.api_key !== null, [state]);

  const units = React.useMemo(() => {
    return state.user ? state.user.units : undefined;
  }, [state.user]);

  const getSetting = React.useCallback((name: string): string | number | boolean | null => {
    const s = state.user?.settings?.find((s: UserSetting) => s.name === name)
    if (s) {
      return s.value;
    } else {
      return null;
    }
  }, [state]);

  const setCurrentHeartland = (heartlandId: number): boolean => {
    if (state.user && !isAdmin() && state.user.heartlands) {
      const selected = state.user.heartlands.find((h: HeartlandModel) => h.id === heartlandId);
      setState({ ...state, heartland: selected ? selected : null });
      return true;
    }
    return false;
  };

  const setCurrentUnit = (unitId: number): boolean => {
    if (state.user && !isAdmin() && state.user.units) {
      const selected = state.user.units.find((h: UnitModel) => h.id === unitId);
      setState({ ...state, unit: selected ? selected : null });
      return true;
    }
    return false;
  };

  return (
    <SessionContext.Provider value={{
      currentUser: state.user,
      currentHeartland: state.heartland,
      currentUnit: state.unit,
      message: state.message,
      units,
      login: login,
      isAdmin: isAdmin,
      isOwner: isOwner,
      isHeartlandLive: isHeartlandLive,
      setCurrentHeartland: setCurrentHeartland,
      setCurrentUnit: setCurrentUnit,
      getSetting: getSetting,
      flashMessage: flashMessage,
      setFlashMessage: setFlashMessage,
    }}>
      {children}
    </SessionContext.Provider >
  );
};